#App {
  p {
    color: rgb(128, 128, 128);
  }

  .link {
    text-decoration: none;
    color: #07a6a1;
  }

  .link:hover {
    text-decoration: none;
    color: #006d69;
  }

  .btn-primary {
    background-color: #07a6a1;
    border: none;
  }

  .btn-primary:hover {
    background-color: #007a76;
  }

  .bg-primary {
    background-color: #07a6a1 !important;
  }

  .main-title {
    font-size: 1.7rem;
    font-weight: normal;
  }

  .header-title {
    font-size: 1.2rem;
    font-weight: normal;
  }

  .product-thumbnail {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }

  .total-price {
    color: black;
    font-weight: bold;
    font-size: 1.7rem;
  }
}